import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch } from 'react-redux';
import { showInfoSnackbar } from '../../redux/actions/uiActions';
import ContactListToggle from './ContactListToggle';
import { moveItem } from '../utilities';
import { useContactGroupsQuery } from '../../hooks/contactGroups/useContactGroupsQuery';
import useUpdateContact from '../../hooks/contacts/useUpdateContact';
import useUpdateContactGroup from '../../hooks/contactGroups/useUpdateContactGroup';
import { useGetSchool } from '../../hooks/contacts/useContactsQuery';
import { hasAdminRole } from '../utilities';

const ContactMoreMenu = (props) => {
  const {
    contact,
    index,
    listId,
    count,
    listEditingAllowed,
    anchorEl,
    setAnchorEl,
  } = props;

  const lists = useContactGroupsQuery()?.data;
  const list = useContactGroupsQuery()?.data?.find(
    (l) => l.id === listId
  );
  const school = useGetSchool();
  const menuOpen = Boolean(anchorEl);
  const dispatch = useDispatch();
  const updateContact = useUpdateContact();
  const updateContactGroup = useUpdateContactGroup();

  const ITEM_HEIGHT = 48;

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const removeContactFromList = () => {
    updateContactGroup.mutate(
      {
        contactGroup: {
          ...list,
          contacts: list.contacts.filter((c) => c.id !== contact.id),
        },
      },
      {
        onSuccess: () => {
          dispatch(showInfoSnackbar('Removed from list'));
        },
        onError: () => {
          dispatch(showInfoSnackbar('An error occurred'));
        },
      }
    );
  };

  // Reorder function explanation:
  // When reordering the contacts, we only allow non-admin contacts to be moved.
  // However, the backend relies on having the creator admin included in the list
  // (to easily filter the current signed admin's contactGroups). Therefore, we
  // separate out the admin contacts, reorder only the non-admins, and then merge
  // the admin contacts back into the updated list before sending the update to the API.
  const reorderContacts = (nonAdminIndex, direction) => {
    // Separate admin contacts and non-admin contacts.
    const adminContacts = list.contacts.filter((c) =>
      hasAdminRole(c.role)
    );
    const nonAdminContacts = list.contacts.filter(
      (c) => !hasAdminRole(c.role)
    );

    // Reorder the non-admin contacts using the provided index and direction.
    // Note: 'nonAdminIndex' should correspond to the index in the filtered (non-admin) list.
    const reorderedNonAdmin = moveItem(
      nonAdminContacts,
      nonAdminIndex,
      direction
    );

    // Merge the admin contacts back into the reordered list.
    const updatedContacts = [...adminContacts, ...reorderedNonAdmin];

    updateContactGroup.mutate(
      {
        contactGroup: {
          ...list,
          contacts: updatedContacts,
        },
      },
      {
        onSuccess: () => {
          dispatch(showInfoSnackbar('List reordered'));
        },
        onError: () => {
          dispatch(showInfoSnackbar('An error occurred'));
        },
      }
    );

    handleMenuClose();
  };

  const handleStatusChange = (contact) => {
    const { status, ...data } = contact;

    updateContact.mutate({
      contact: {
        ...data,
        status: contact.status === 'Active' ? 'Inactive' : 'Active',
      },
    });

    dispatch(
      showInfoSnackbar(
        `Contact set to ${
          contact.status === 'active' ? 'inactive' : 'active'
        }`
      )
    );
  };

  return (
    <Menu
      id="list-menu"
      component="div"
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 10.5,
          width: '30ch',
        },
      }}
    >
      {listEditingAllowed && (
        <div>
          <MenuItem
            onClick={() => reorderContacts(index, 'up')}
            disabled={index === 0}
          >
            <ListItemText primary="Move up" />
          </MenuItem>
          <MenuItem
            onClick={() => reorderContacts(index, 'down')}
            disabled={index + 1 === count}
          >
            <ListItemText primary="Move down" />
          </MenuItem>
          <Divider component="li" />
          <MenuItem
            onClick={() => removeContactFromList(listId, contact.id)}
          >
            <ListItemText primary="Remove from list" />
          </MenuItem>
        </div>
      )}
      <MenuItem onClick={() => handleStatusChange(contact)}>
        <ListItemText
          primary={`Set to ${
            contact.status === 'Active' ? 'Inactive' : 'Active'
          }`}
        />
      </MenuItem>
      {/*  
        Admins cannot be added to lists because lists are used for
        bookings, and admins generally do not receive bookings. 
        Therefore, we hide ContactListToggle for admins to prevent
        unnecessary actions.
      */}
      {!hasAdminRole(contact.role) && <Divider component="li" />}
      {!hasAdminRole(contact.role) && (
        <ContactListToggle
          contact={contact}
          lists={lists?.filter(
            (cg) =>
              // Ensure the list belongs to the same school and exclude "all-" lists
              cg.school === school?.school &&
              !cg.text.includes('all-')
          )}
        />
      )}
    </Menu>
  );
};

export default ContactMoreMenu;

// const useStyles = makeStyles((theme) => ({

// }));
