import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Loading from './Loading';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType, InteractionType } from '@azure/msal-browser';
import { b2cPolicies } from '../authConfig';
import MainLayout from './layout/MainLayout';
import { useContactQuery } from '../hooks/contacts/useContactsQuery';

import HomeView from '../views/Home';
import CreateBookingView from '../views/CreateBooking';
import EditBookingView from '../views/EditBooking';
import ContactsView from '../views/Contacts';
import ContactView from '../views/Contact';
import ListView from '../views/List';
import AckBookingView from '../views/AckBooking';
import SettingsView from '../views/Settings';
import TeachersView from '../views/Teachers';
import SchoolsView from '../views/Schools';
import SchoolView from '../views/School';
import CreateSchoolView from '../views/CreateSchool';
import MyBookingsView from '../views/MyBookings';
import GetStartedView from '../views/GetStarted';
import WaitingForApprovalView from '../views/WaitingForApproval';
import NotFoundView from '../views/404';
import { hasAdminRole } from './utilities';

import ReactGA from 'react-ga';

// Google Tag Manager init
const TRACKING_ID = 'G-P04BMSDNYZ';
ReactGA.initialize(TRACKING_ID);

const Pages = () => {
  // Capture GA page views
  useEffect(() => {
    ReactGA.pageview(
      window.location.pathname + window.location.search
    );
  }, []);

  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance, accounts, inProgress } = useMsal();

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (
          event.error &&
          event.error.errorMessage.indexOf('AADB2C90118') > -1
        ) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(
              b2cPolicies.authorities.forgotPassword
            );
          } else if (
            event.interactionType === InteractionType.Popup
          ) {
            instance
              .loginPopup(b2cPolicies.authorities.forgotPassword)
              .catch(() => {
                return;
              });
          }
        }
      }

      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        if (event?.payload) {
          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (
            event.payload.idTokenClaims['acr'] ===
            b2cPolicies.names.forgotPassword
          ) {
            window.alert(
              'Password has been reset successfully. \nPlease sign-in with your new password.'
            );
            return instance.logout();
          } else if (
            event.payload.idTokenClaims['acr'] ===
            b2cPolicies.names.editProfile
          ) {
            window.alert(
              'Profile has been edited successfully. \nPlease sign-in again.'
            );
            return instance.logout();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  // Routes for Admins
  // Check user's role property to determine if the user is an admin
  // Pass an adminOnly or superAdminOnly prop to the page for conditional rendering
  // If adminOnly is true, the route is accessible only by admins
  // If superAdminOnly is true, the route is accessible only by super admins
  // Passing the user object to the page in both scenarios
  // so it can be checked for conditional rendering within the page
  const CreateRoute = (props) => {
    const userQuery = useContactQuery(accounts[0]?.username);
    const isAdmin = hasAdminRole(userQuery?.data?.role);
    const isSuperAdmin = userQuery?.data?.role === 'superAdmin';

    return userQuery.isLoading ? (
      <Loading />
    ) : !props.adminOnly && !props.superAdminOnly ? (
      <Route path={props.path} exact>
        <props.component user={userQuery?.data} />
      </Route>
    ) : (isAdmin &&
        userQuery?.data?.status !== 'PendingApproval' &&
        !props.superAdminOnly) ||
      (isSuperAdmin && props.superAdminOnly) ? (
      <Route path={props.path} exact>
        <props.component user={userQuery?.data} />
      </Route>
    ) : (
      <div>Page not found</div>
    );
  };

  return (
    <BrowserRouter>
      <Switch>
        {accounts && inProgress === 'none' && (
          <Route>
            <MainLayout>
              <Switch>
                <CreateRoute
                  path="/booking/new"
                  component={CreateBookingView}
                  adminOnly
                />
                <CreateRoute
                  path="/booking/copy/:id"
                  component={CreateBookingView}
                  adminOnly
                />
                <CreateRoute
                  path="/booking/:id/edit"
                  component={EditBookingView}
                  adminOnly
                />
                <CreateRoute
                  path="/contacts"
                  component={ContactsView}
                  adminOnly
                />
                <CreateRoute
                  path="/contact/:id"
                  component={ContactView}
                  adminOnly
                />
                <CreateRoute
                  path="/list/:id"
                  component={ListView}
                  adminOnly
                />
                <CreateRoute path="/" exact component={HomeView} />
                <CreateRoute
                  path="/ack-booking/:id"
                  exact
                  component={AckBookingView}
                />
                <CreateRoute
                  path="/bookings"
                  exact
                  component={MyBookingsView}
                />
                <CreateRoute
                  path="/settings"
                  component={SettingsView}
                />
                <CreateRoute
                  path="/teachers"
                  component={TeachersView}
                  adminOnly
                />
                <CreateRoute
                  path="/schools"
                  component={SchoolsView}
                  adminOnly
                  superAdminOnly
                />
                <CreateRoute
                  path="/school/:id"
                  component={SchoolView}
                  adminOnly
                  superAdminOnly
                />
                <CreateRoute
                  path="/create-school"
                  component={CreateSchoolView}
                  adminOnly
                  superAdminOnly
                />
                <CreateRoute
                  path="/get-started"
                  exact
                  component={GetStartedView}
                />
                <CreateRoute
                  path="/waiting-for-approval"
                  exact
                  component={WaitingForApprovalView}
                />
                <CreateRoute path="*" component={NotFoundView} />
              </Switch>
            </MainLayout>
          </Route>
        )}
      </Switch>
    </BrowserRouter>
  );
};

const App = () => {
  const { instance } = useMsal();
  return (
    <BrowserRouter>
      <MsalProvider instance={instance}>
        <Pages />
      </MsalProvider>
    </BrowserRouter>
  );
};

export default App;
