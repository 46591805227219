import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/PhoneIphone';
import { useHistory, useParams } from 'react-router-dom';
import ContactHeader from '../components/contact/ContactHeader';
import ContactMoreMenu from '../components/contact/ContactMoreMenu';
import Loading from '../components/Loading';
import { useContactQuery } from '../hooks/contacts/useContactsQuery';
import { useGetSchool } from '../hooks/contacts/useContactsQuery';
import NotFound from './404';
import { weekDays } from '../components/utilities';
import ContactApproval from '../components/contact/ContactApproval';
import { hasAdminRole } from '../components/utilities';

const Contact = () => {
  const classes = useStyles();
  const { id } = useParams();
  const contactQuery = useContactQuery(id);
  const school = useGetSchool();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBack = () => history.goBack();

  // Has the contact been approved by the school of the logged in admin?
  const waitingForApproval = contactQuery?.data?.requestedSchools
    ?.split(',')
    .find((l) => l === school?.id);

  const renderContact = (contact) => {
    return (
      contactQuery.data && (
        <>
          <AppBar position="static" color="transparent" elevation={0}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="back"
                onClick={handleBack}
              >
                <ArrowBackIcon />
              </IconButton>
              <div className={classes.menuButton}>
                {!!waitingForApproval ? (
                  <ContactApproval
                    contact={contactQuery?.data}
                    school={school}
                    buttonSize="large"
                  />
                ) : (
                  <>
                    <IconButton
                      edge="end"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleMenuClick}
                      disabled={!!waitingForApproval}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <ContactMoreMenu
                      contact={contact}
                      listEditingAllowed={false}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      menuContext="page"
                    />
                  </>
                )}
              </div>
            </Toolbar>
          </AppBar>
          <section className={classes.root}>
            <ContactHeader
              contact={contact}
              school={school}
              waitingForApproval={!!waitingForApproval}
            />
            <Card variant="outlined" className={classes.card}>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  Contact details
                </Typography>
                <List dense className={classes.list}>
                  <ListItem>
                    <ListItemIcon>
                      <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>
                        <a href={`tel:${contact.mobile}`}>
                          0{contact.mobile}
                        </a>
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>
                        <a
                          href={`mailto:${contact.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {contact.id}
                        </a>
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
            {/* Days available not required for admins */}
            {!hasAdminRole(contact.role) && (
              <Card variant="outlined" className={classes.card}>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    Days available
                  </Typography>
                  <Box display="flex" pt={1.5}>
                    {weekDays.map((day) => {
                      const daysNotAvailable =
                        contact.daysNotAvailable?.split(',');

                      return (
                        <Avatar
                          key={day}
                          className={`${classes.avatar} ${
                            day.substring(0, 3) ===
                            daysNotAvailable?.find(
                              (ua) => ua === day.substring(0, 3)
                            )
                              ? null
                              : classes.available
                          }`}
                        >
                          {day[0]}
                        </Avatar>
                      );
                    })}
                  </Box>
                </CardContent>
              </Card>
            )}
          </section>
        </>
      )
    );
  };

  return (
    <>
      {contactQuery.isLoading ? (
        <Loading />
      ) : contactQuery?.data?.statusCode === 500 ? (
        <NotFound />
      ) : (
        renderContact(contactQuery.data)
      )}
    </>
  );
};

export default Contact;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 2),
    maxWidth: '34em',
  },
  menuButton: {
    marginLeft: 'auto',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    color: `rgba(255,255,255,0.7)`,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  editButton: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  list: {
    '& .MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.dark,
    },
  },
  card: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius * 2,
    marginBottom: theme.spacing(2),
    '& .MuiCardContent-root': {
      paddingBottom: theme.spacing(2),
    },
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(5),
    },
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: theme.typography.caption.fontSize,
    marginRight: theme.spacing(1),
  },
  available: {
    backgroundColor: theme.palette.primary.main,
  },
}));
