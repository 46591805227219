import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ContactListItem from '../components/contact/ContactListItem';
import { useParams } from 'react-router-dom';
import EmptyState from '../components/EmptyState';
import Loading from '../components/Loading';
import { useContactGroupsQuery } from '../hooks/contactGroups/useContactGroupsQuery';
import NotFound from './404';
import { hasAdminRole } from '../components/utilities';

const ListView = () => {
  // const classes = useStyles();
  const { id } = useParams();
  const contactGroupsQuery = useContactGroupsQuery();
  const contactGroup = contactGroupsQuery?.data?.find(
    (cg) => cg.id === id
  );

  const renderList = (contactGroup) => {
    // Filter out admin contacts from lists.
    // The admin who creates a list is automatically added to it.
    // This is intentional, as it allows us to filter lists on the backend,
    // ensuring that only relevant lists are returned per admin.
    // However, we do not want the creator admin to be visible in the list on the frontend.
    const filteredContacts = contactGroup.contacts.filter(
      (contact) => !hasAdminRole(contact.role)
    );

    return (
      <List
        subheader={
          filteredContacts.length !== 0 ? (
            <ListSubheader component="div" id="list-subheader">
              {contactGroup.text} ({filteredContacts.length})
            </ListSubheader>
          ) : (
            false
          )
        }
      >
        {filteredContacts.map((contact, index) => {
          const contactListCount = contactGroupsQuery?.data?.filter(
            (cg) =>
              cg.contacts.find(
                (c) =>
                  c.id === contact.id &&
                  cg.school === contactGroup.school
              )
          ).length;

          return (
            <ContactListItem
              key={contact.id}
              listId={id}
              index={index}
              contact={contact}
              count={filteredContacts.length}
              contactListCount={contactListCount - 1}
              listEditingAllowed
            />
          );
        })}
      </List>
    );
  };

  return contactGroupsQuery.isLoading ? (
    <Loading />
  ) : contactGroupsQuery?.data?.statusCode === 500 ? (
    <NotFound />
  ) : // Empty state is still displayed for length === 1
  // due to the creator admin existing in the contacts response
  // They are filtered from view
  contactGroup?.contacts?.length === 1 ? (
    <>
      <EmptyState
        text="No contacts on this list"
        icon="import-contacts"
      />
      <div style={{ textAlign: 'center' }}>
        <Button color="primary" component={Link} to="/contacts">
          Add Contacts
        </Button>
      </div>
    </>
  ) : (
    renderList(contactGroup)
  );
};

export default ListView;

// const useStyles = makeStyles((theme) => ({}));
