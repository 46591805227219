import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useContactQuery } from '../hooks/contacts/useContactsQuery';
import useUpdateContact from '../hooks/contacts/useUpdateContact';
import { useAccount, useMsal } from '@azure/msal-react';
import { showInfoSnackbar } from '../redux/actions/uiActions';
import { useDispatch } from 'react-redux';
import { weekDays } from '../components/utilities';
import { hasAdminRole } from '../components/utilities';

const Settings = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  const contactQuery = useContactQuery(account.username);
  const contact = contactQuery.data;
  const { mutate: updateContact } = useUpdateContact();

  const handleStatusChange = (event) => {
    updateContact(
      {
        contact: {
          ...contact,
          status: event.target.checked ? 'Active' : 'Inactive',
        },
      },
      {
        onSuccess: () =>
          dispatch(showInfoSnackbar('Settings updated')),
        onError: () =>
          dispatch(showInfoSnackbar('An error occurred')),
      }
    );
  };

  const handleDaysAvailableChange = (event) => {
    let daysNotAvailable = contact?.daysNotAvailable
      ? [...contact?.daysNotAvailable.split(',')]
      : [];

    if (event.target.checked) {
      daysNotAvailable = daysNotAvailable.filter(
        (day) => day !== event.target.name.substring(0, 3)
      );
    } else {
      daysNotAvailable.push(event.target.name.substring(0, 3));
    }

    updateContact({
      contact: {
        ...contact,
        daysNotAvailable: daysNotAvailable.length
          ? daysNotAvailable.toString()
          : null,
      },
    });
  };

  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Typography variant="h6" className={classes.subtitle}>
        Settings
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={contactQuery?.data?.status === 'Active'}
              onChange={handleStatusChange}
              name="Set to active"
              color="primary"
            />
          }
          label="Set to active"
        />
        {!hasAdminRole(contact?.role) ? (
          <Typography variant="caption" color="textSecondary">
            You will only receive bookings when your status is set to
            active
          </Typography>
        ) : (
          <Typography variant="caption" color="textSecondary">
            You will only receive email notifications about platform
            activity when your account is active
          </Typography>
        )}
      </FormGroup>
      <br />
      <br />
      {!hasAdminRole(contact?.role) && (
        <>
          <Typography variant="h6" className={classes.subtitle}>
            Work Days
          </Typography>
          <FormGroup>
            {weekDays.map((weekDay) => {
              const notAvailable =
                contact?.daysNotAvailable &&
                contact?.daysNotAvailable
                  .split(',')
                  .find((day) => day === weekDay.substring(0, 3));

              return (
                <FormControlLabel
                  key={weekDay}
                  disabled={contactQuery?.data?.status === 'Inactive'}
                  control={
                    <Checkbox
                      checked={
                        notAvailable !== weekDay.substring(0, 3)
                      }
                      onChange={handleDaysAvailableChange}
                      name={weekDay}
                      color="primary"
                    />
                  }
                  label={weekDay}
                />
              );
            })}
            <Typography variant="caption" color="textSecondary">
              You will only receive bookings on days that are checked
            </Typography>
          </FormGroup>
        </>
      )}
    </section>
  );
};

export default Settings;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: '34em',
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
}));
