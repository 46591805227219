import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  isBeforeToday,
  isSameOrAfter,
  isBeforeNow,
} from '../components/momentUtilities';
import BookingItem from '../components/bookings/BookingItem';
import EmptyState from '../components/EmptyState';
import Loading from '../components/Loading';
import { useBookingsQuery } from '../hooks/bookings/useBookingsQuery';
import { useContactGroupsQuery } from '../hooks/contactGroups/useContactGroupsQuery';
import { useGetSchool } from '../hooks/contacts/useContactsQuery';
import { hasAdminRole } from '../components/utilities';

const MyBookings = (props) => {
  const classes = useStyles();
  const bookings = useBookingsQuery();
  const contactGroupsQuery = useContactGroupsQuery();
  const schoolOfUser = useGetSchool();

  const bookingsData = bookings?.data?.map((booking) => {
    const acceptedBy = booking?.contacts.find(
      (contact) => contact.status === 'accepted'
    );

    const rejectedBy = booking?.contacts.filter(
      (contact) => contact.status === 'rejected'
    );

    const schoolOfCreator = contactGroupsQuery?.data?.find(
      (cg) =>
        cg.text.includes('all-') &&
        cg.contacts.find((c) => c.id === booking.createdBy.id)
    );

    // Add in some extra status functionality
    // Past bookings = Cancelled
    // Rejected by all = Rejected
    const status =
      booking.status === 'CANCELLED'
        ? booking.status
        : acceptedBy
        ? 'ACCEPTED'
        : isBeforeNow(booking.startDate)
        ? 'CANCELLED'
        : rejectedBy.length === booking.contacts.length
        ? 'REJECTED'
        : booking.status;

    return {
      ...booking,
      id: booking.id,
      status,
      start: booking.startDate,
      end: booking.endDate,
      acceptedBy,
      adminCanView: hasAdminRole(props.user?.role)
        ? schoolOfUser?.school === schoolOfCreator?.school
        : true, // Creating this property to filter admins booking for their school only
      school: schoolOfCreator?.school,
      title: !hasAdminRole(props.user?.role)
        ? schoolOfCreator
          ? schoolOfCreator.school
          : ''
        : status === 'CANCELLED'
        ? 'Cancelled'
        : acceptedBy
        ? `${acceptedBy.firstName} ${acceptedBy.surname}`
        : 'Organising cover',
    };
  });

  const upcomingBookings = bookingsData?.filter((booking) => {
    return (
      isSameOrAfter(booking.startDate) &&
      (props.user?.id === booking.acceptedBy?.contactId ||
        hasAdminRole(props.user?.role)) &&
      booking.adminCanView
    );
  });

  const previousBookings = bookingsData?.filter((booking) => {
    return (
      isBeforeToday(booking.startDate) &&
      (props.user?.id === booking.acceptedBy?.contactId ||
        hasAdminRole(props.user?.role)) &&
      booking.adminCanView
    );
  });

  return (
    <>
      {bookings.isLoading ? (
        <Loading />
      ) : (
        <section className={classes.root}>
          <>
            <Box py={1} width={1}>
              <Typography variant="subtitle1" gutterBottom>
                Upcoming
              </Typography>
              {upcomingBookings && upcomingBookings.length === 0 ? (
                <EmptyState
                  text="No upcoming bookings"
                  icon="calendarToday"
                />
              ) : (
                upcomingBookings
                  ?.sort(
                    (a, b) =>
                      new Date(a.startDate) - new Date(b.startDate)
                  )
                  .map((booking) => (
                    <BookingItem
                      key={booking.id}
                      user={props.user}
                      {...booking}
                    />
                  ))
              )}
            </Box>
            <Box py={1} width={1}>
              <Typography variant="subtitle1" gutterBottom>
                Previous
              </Typography>
              {previousBookings && previousBookings.length === 0 ? (
                <EmptyState
                  text="No previous bookings"
                  icon="calendarToday"
                />
              ) : (
                previousBookings
                  ?.sort(
                    (a, b) =>
                      new Date(b.startDate) - new Date(a.startDate)
                  )
                  .map((booking) => (
                    <BookingItem
                      key={booking.id}
                      status={booking.status}
                      user={props.user}
                      {...booking}
                    />
                  ))
              )}
            </Box>
          </>
        </section>
      )}
    </>
  );
};

export default MyBookings;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 1),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
    },
  },
}));
